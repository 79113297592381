<template>
    <!-- e-task-panel-ajust 调整  -->
    <div
        class="e-task-panel e_task_panel e-task-panel-ajust"
        v-loading="loading"
    >
        <el-tabs
            v-model="activeName"
            @tab-click="change"
        >
            <el-tab-pane
                :label="finishedCount"
                name="first"
            >
                <ul
                    class="evaluate-items"
                    v-if="task.length>0"
                >
                    <li
                        class="item"
                        v-for="(item, index) in task"
                        :key="index"
                    >
                        <div class="avatar">
                            <img :src="util.reImg(item.employer.avatar)" />
                        </div>
                        <div class="evaluate-cont">
                            <div class="hd">
                                <time class="date">{{item.approve_time}}</time>
                                <h4 class="title">{{item.employer.name}}</h4>
                            </div>
                            <p class="price">
                                项目酬金：
                                <span>{{item.trade?item.trade.remuneration:item.remuneration}}</span>
                            </p>
                            <div
                                class="project-name task_title"
                                @click="detail(item.tk)"
                            >项目名称：{{item.task_name}}</div>
                            <!-- <div class="evaluate-txt">
                                {{item.employer_appraises_info}}
                                <a
                                    href="javascript:void(0)"
                                    class="more"
                                >显示全部</a>
                            </div>-->
                            <div
                                class="evaluate-txt"
                                v-if="item.appraise"
                            >
                                <span v-if="item.appraise && item.appraise.geek_content">
                                    <div style="color:#999">{{item.geek.name}}（极客）评价：</div>
                                    <p class="row-star starpl-wrap">
                                        <span style="float:left;">需求明确：</span>
                                        <el-rate
                                            style="float:left;"
                                            :value="item.appraise.demand_score"
                                            disabled
                                            show-score
                                            text-color="#ff9900"
                                            score-template
                                        ></el-rate>
                                        <span>{{item.appraise.demand_score}}分</span>
                                    </p>

                                    <p class="row-star starpl-wrap">
                                        <span style="float:left;">支付速度：</span>
                                        <el-rate
                                            style="float:left;"
                                            :value="item.appraise.pay_score"
                                            disabled
                                            show-score
                                            text-color="#ff9900"
                                            score-template
                                        ></el-rate>
                                        <span>{{item.appraise.pay_score}}分</span>
                                    </p>

                                    <p class="row-star starpl-wrap">
                                        <span style="float:left;">对接态度：</span>
                                        <el-rate
                                            style="float:left;"
                                            :value="item.appraise.dock_score"
                                            disabled
                                            show-score
                                            text-color="#ff9900"
                                            score-template
                                        ></el-rate>
                                        <span>{{item.appraise.dock_score}}分</span>
                                    </p>
                                    <div
                                        style="color:#666"
                                        v-text="item.appraise.geek_content"
                                    ></div>
                                </span>
                                <!--<span v-if="item.appraise && item.appraise.employer_content">
                                    <div style="color:#999;margin-top:10px;">雇主评价：</div>
                                    <div style="color:#666"
                                         v-text="item.appraise.employer_content"></div>
                                </span>-->
                            </div>
                        </div>
                    </li>
                </ul>
                <div
                    v-else
                    style="width:100%;height:300px;line-height:300px;text-align:center;font-size:14px;color:#999;"
                >暂无数据</div>
            </el-tab-pane>

            <el-tab-pane
                :label="doingCount"
                name="second"
            >
                <ul
                    class="e-task-items"
                    v-if="task.length>0"
                >
                    <li
                        class="e-task-item"
                        v-for="(item, index) in task"
                        :key="index"
                    >
                        <div class="top">
                            <h4
                                @click="detail(item.tk)"
                                class="title task_title"
                            >{{item.task_name}}</h4>
                            <div
                                class="right-txt"
                                style="width:273px;"
                            >
                                <span
                                    class="status"
                                    style="padding-left: 5px;"
                                    :class="{ green: item.status == 3, blue: item.status ==4, orange: item.status == 5, red: item.status ==6}"
                                > {{ item.status_value }}</span>
                                <span
                                    class="price"
                                    v-if="item.task_type==1 || item.task_type==2"
                                >
                                    ￥
                                    <strong>{{item.trade?item.trade.remuneration:item.remuneration}}</strong>
                                </span>
                                <span
                                    class="price"
                                    v-else
                                >
                                    ￥
                                    <strong>{{ item.trade?item.trade.remuneration:item.remuneration }}</strong>
                                </span>
                            </div>
                        </div>
                        <div class="bottom task_tag">
                            <div class="tag-box">
                                <span
                                    class="jiaji"
                                    v-if="item.is_hot==1"
                                >
                                    <i class="jk-icon-jiaji"></i>热门
                                </span>

                                <span :class="{jingjia: item.task_type == 1,bixuan:  item.task_type  == 2,bixuan:  item.task_type == 3}">{{ item.task_type_value }}</span>
                            </div>
                            <div
                                class="tips-box tips_box "
                                style="width:273px;"
                            >
                                <span style="padding-right:20px;margin-left: 0px;">{{ item.speciality_type_value }}</span>
                                <span>
                                    {{ item.enroll_count }} 人已投标
                                </span>
                                <span>
                                    {{ item.end_time_value }} 日后截止
                                </span>
                            </div>
                        </div>
                    </li>
                </ul>
                <div
                    v-else
                    style="width:100%;height:300px;line-height:300px;text-align:center;font-size:14px;color:#999;"
                >暂无数据</div>
            </el-tab-pane>

            <el-tab-pane
                :label="otherCount"
                name="third"
            >
                <ul
                    class="e-task-items"
                    v-if="task.length>0"
                >
                    <li
                        class="e-task-item"
                        v-for="(item, index) in task"
                        :key="index"
                    >
                        <div class="top">
                            <h4
                                @click="detail(item.tk)"
                                class="title task_title"
                            >{{item.task_name}}</h4>
                            <div
                                class="right-txt"
                                style="width:273px;"
                            >
                                <span
                                    class="status"
                                    style="padding-left: 5px;"
                                    :class="{ green: item.status == 3, blue: item.status ==4, orange: item.status == 5, red: item.status ==6}"
                                > {{ item.status_value }}</span>
                                <span
                                    class="price"
                                    v-if="item.task_type==1 || item.task_type==2"
                                >
                                    ￥
                                    <strong>{{item.trade?item.trade.remuneration:item.remuneration}}</strong>
                                </span>
                                <span
                                    class="price"
                                    v-else
                                >
                                    ￥
                                    <strong>{{ item.trade?item.trade.remuneration:item.remuneration }}</strong>
                                </span>
                            </div>
                        </div>
                        <div class="bottom task_tag">
                            <div class="tag-box">
                                <span
                                    class="jiaji"
                                    v-if="item.is_hot==1"
                                >
                                    <i class="jk-icon-jiaji"></i>热门
                                </span>

                                <span :class="{jingjia: item.task_type == 1,bixuan:  item.task_type  == 2,bixuan:  item.task_type == 3}">{{ item.task_type_value }}</span>
                            </div>
                            <div
                                class="tips-box tips_box "
                                style="width:273px;"
                            >
                                <span style="padding-right:20px;margin-left: 0px;">{{ item.speciality_type_value }}</span>
                                <span>
                                    {{ item.enroll_count }} 人已投标
                                </span>
                                <span>
                                    {{ item.end_time_value }} 日后截止
                                </span>
                            </div>
                        </div>
                    </li>
                </ul>
                <div
                    v-else
                    style="width:100%;height:300px;line-height:300px;text-align:center;font-size:14px;color:#999;"
                >暂无数据</div>
            </el-tab-pane>
        </el-tabs>
        <pagination
            v-if="task.length > 0"
            :currentPage="page.currentPage"
            :pageSize="page.pageSize"
            :pageTotal="page.pageTotal"
            @current-change="newPage"
        ></pagination>
    </div>
</template>

<script>
import Pagination from "@/components/common/Pagination.vue";
export default {
    name: "task",
    components: {
        Pagination //分页
    },
    data() {
        return {
            task: [],
            finishedCount: "已完成项目(1)",
            doingCount: "进行中项目(0)",
            otherCount: "其他项目(0)",
            activeName: "first",
            loading: false,
            type: 1,
            page: {
                currentPage: 1,
                pageSize: 10,
                pageTotal: 0
            },
            evaluate: [
                {
                    avatar: this.$img.firm_avatar,
                    date: "2019-3-24  13:14:58",
                    title: "云南极客信息工程有限公司昆明分公司",
                    price: "￥12,5478元",
                    project:
                        "项目名称：关于盘龙区工程勘察的快包项目关于盘龙区工程勘察的快包",
                    assess:
                        "此工程极客成果交付及时，工作态度认真，十分靠谱！下次还找他，5星好评！此工程极客成果交付及时，工作态度认真，十分靠谱！下次还找他，5星好评！此工程极客成果交付及时，工作态度认真，十分靠谱！下次还找他，5星好评下次还找他，5星好评下次还找他，5星好评"
                }
            ]
        };
    },
    created() {
        this.loadListData(); //获取外部router-view传进来的data-id属性值
    },
    methods: {
        //获取项目信息
        loadListData() {
            let _this = this;
            _this.task = [];
            _this.loading = true;
            _this.post(
                "/user/index/task_list",
                {
                    uk: _this.$route.query.uk,
                    type: _this.type,
                    page: 1,
                    limit: 10
                },
                function (res) {
                    _this.loading = false;
                    if (res.code == 200) {
                        _this.task = res.data.list;
                        _this.page.pageTotal = res.data.count;
                        _this.finishedCount =
                            "已完成项目(" + res.data.finished + ")";
                        _this.doingCount =
                            "进行中项目(" + res.data.doing + ")";
                        _this.otherCount =
                            "其他项目(" + res.data.other + ")";
                    }
                }
            );
        },

        //切换tab
        change() {
            this.page.currentPage = 1; //切换到第一页
            this.loadData(); //加载数据
        },

        //加载数据
        loadData() {
            if ("first" == this.activeName) {
                this.type = 1;
                this.loadListData();
            } else if ("second" == this.activeName) {
                this.type = 2;
                this.loadListData();
            } else {
                this.type = 3;
                this.loadListData();
            }
        },

        //分页
        newPage(val) {
            this.page.currentPage = val;
            this.loadData(); //加载数据
        },

        //项目详情
        detail(tk) {
            this.$router.push("/task/detail?tk=" + tk);
        }
    }
};
</script>
<style lang="less">
.task_title:hover {
    opacity: 0.6 !important;
    cursor: pointer;
}
.e_task_panel .evaluate-items .evaluate-cont {
    width: 826px !important;
}
</style>




